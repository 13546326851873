import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  operation: string = 'login'

  email: string = null
  password: string = null
  nick: string = null

  constructor(private autheService: AuthService,
    private userService: UserService,
    private router: Router) { }

  ngOnInit() { }

  login() {
    this.autheService.loginWithEmail(this.email, this.password).then((data) => {
      console.log(data)
      this.router.navigate(['home'])
    }).catch((err) => {
      alert('Error: ' + err.message)
    })
  }

  register() {
    this.autheService.registerWithEmail(this.email, this.password).then((data) => {
      const user = {
        uid: data.user.uid,
        email: this.email,
        nick: this.nick
      }
      this.userService.createUser(user).then((data2) => {
        this.operation = 'login'
      }).catch((err) => {
        alert('Error: ' + err.message)
      })
    }).catch((err) => {
      alert('Error: ' + err.message)
    })
  }

}
