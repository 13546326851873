import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class ConversationService {

  constructor(private angularFiredatabase: AngularFireDatabase) { }

  createConversationFirstTime(conversation, userId, friendId) {
    this.angularFiredatabase.object(`users/${friendId}/conversations/${conversation}`).set(conversation)
    this.angularFiredatabase.object(`users/${userId}/conversations/${conversation}`).set(conversation)
    return this.angularFiredatabase.object(`conversations/${conversation.uid}/${conversation.timestamp}`).set(conversation)
  }
  createConversation(conversation) {
    return this.angularFiredatabase.object(`conversations/${conversation.uid}/${conversation.timestamp}`).set(conversation)
  }

  getConversation(conversation) {
    return this.angularFiredatabase.list(`conversations/${conversation}`)
  }
  getConversationsByUser(uid) {
    return this.angularFiredatabase.list(`users/${uid}/conversations`)
  }

  editConversation(conversation) {
    return this.angularFiredatabase.object(`conversations/${conversation.uid}/${conversation.timestamp}`).update(conversation)
  }

  clearConversation(conversation, userId) {
    return this.angularFiredatabase.object(`users/${userId}/conversations/${conversation}`).remove()
  }

}
