import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(private angularFireDatabase: AngularFireDatabase) { }

  createRequest(request){
    const cleanEmail = request.receiver_email.replace('.',',')
    return this.angularFireDatabase.object(`requests/${cleanEmail}/${request.sender}`).set(request)
  }

  setRequestStatus(request, status){
    const cleanEmail = request.receiver_email.replace('.',',')
    return this.angularFireDatabase.object(`requests/${cleanEmail}/${request.sender}/status`).set(status)
  }

  getRequestsForEmail(email){
    const cleanEmail = email.replace('.',',')
    return this.angularFireDatabase.list(`requests/${cleanEmail}`)
  }

  addFriend(userId, friendId, email) {
    this.angularFireDatabase.object(`/requests/${email}/${friendId}`).update({status: 'accepted'})
    this.angularFireDatabase.object(`/users/${friendId}/friends/${userId}`).set(userId)
    return this.angularFireDatabase.object(`/users/${userId}/friends/${friendId}`).set(friendId)
  }
}
