import { Component, OnInit } from '@angular/core';
import { User } from '../interfaces/user';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { RequestService } from '../services/request.service';
import { ConversationService } from '../services/conversation.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  friends: User[]
  query: string = ''
  friendEmail: string = ''
  user: User = null
  constructor(private userService: UserService,
    private authService: AuthService,
    private requestService: RequestService,
    private conversationService: ConversationService) { }

  ngOnInit() {
    this.userService.getUsers().valueChanges().subscribe((data: User[]) => {
      this.friends = data
    }, (err) => {
      console.log(err)
    })

    this.authService.getStatus().subscribe((status) => {
      this.userService.getUserById(status.uid).valueChanges().subscribe((data: User) => {
        this.user = data
        if(this.user.friends){
          this.user.friends = Object.values(this.user.friends)
        }
      }, (err) => {
        console.log(err)
      })
    })
  }
  
  sendRequest() {
    const request = {
      timestamp: Date.now(),
      receiver_email: this.friendEmail,
      sender: this.user.uid,
      status: 'pending'
    }

    this.requestService.createRequest(request).then(() => {
      alert('Success')
    }).catch((err) => {
      alert('Error')
    })
  }
}
