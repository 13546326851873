import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '../interfaces/user';
import { UserService } from '../services/user.service';
import { ConversationService } from '../services/conversation.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.css']
})
export class ConversationComponent implements OnInit {
  friendId: any
  friend: User
  user: User
  conversationId: string
  textMessage: string
  conversation: any[]
  shake: boolean = false

  constructor(private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private conversationService: ConversationService,
    private authService: AuthService) { }

  ngOnInit() {
    this.friendId = this.activatedRoute.snapshot.params.uid
    this.userService.getUserById(this.friendId).valueChanges().subscribe((data: User) => {
      this.friend = data;
      this.authService.getStatus().subscribe((session) => {
        this.userService.getUserById(session.uid).valueChanges().subscribe((user: User) => {
          this.user = user
          const ids = [this.user.uid, this.friendId].sort()
          this.conversationId = ids.join('|')
          this.getConversation()
        }, (err) => {
          console.log(err)
        })
      })
    }, (err) => {
      console.log(err)
    })
  }

  sendMessage() {
    const message = {
      uid: this.conversationId,
      timestamp: Date.now(),
      seen: false,
      text: this.textMessage,
      sender: this.user.uid,
      receiver: this.friendId,
      type: 'text'
    }
    this.conversationService.createConversation(message).then(() => {
      this.textMessage = ''
    })
  }
  sendBuzz() {
    const message = {
      uid: this.conversationId,
      timestamp: Date.now(),
      seen: false,
      text: null,
      sender: this.user.uid,
      receiver: this.friendId,
      type: 'buzz'
    }
    this.conversationService.createConversation(message).then(() => { })
    this.doBuzz()
  }

  doBuzz() {
    const audio = new Audio('assets/sound/zumbido.m4a')
    audio.play()
    this.shake = true
    window.setTimeout(() => {
      this.shake = false
    }, 1000)
  }

  getConversation() {
    this.conversationService.getConversation(this.conversationId).valueChanges().subscribe((data) => {
      this.conversation = data
      if (this.conversation[0] == null) {
        this.conversationService.createConversationFirstTime(this.conversationId, this.user.uid, this.friendId)
      } else {
        this.conversation.forEach((message) => {
          if (!message.seen) {
            message.seen = true
            if (message.type === 'text') {
              // const audio = new Audio('assets/sound/new_message.m4a')
              // audio.play()
            } else if (message.type === 'buzz') {
              this.doBuzz()
            }
            this.conversationService.editConversation(message)
          }
        })
      }
    })
  }

  getUserNickById(id) {
    if (id == this.friendId) {
      return this.friend.nick
    } else {
      return this.user.nick
    }
  }

}
