import { Component, OnInit } from '@angular/core';
import { User } from '../interfaces/user';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  user: User
  imageChangedEvent: any = '';
  croppedImage: any = '';
  picture: any

  constructor(private userService: UserService, private authService: AuthService, private angularFireStorage: AngularFireStorage, private router: Router) {
    this.authService.getStatus().subscribe((status) => {
      this.userService.getUserById(status.uid).valueChanges().subscribe((data: User) => {
        this.user = data
      }, (err) => {
        console.log(err)
      })
    }, (err) => {
      console.log(err)
    })
  }

  ngOnInit() {
  }

  saveSettings() {
    if (this.croppedImage) {
      const currentPictureId = Date.now();
      const pictures = this.angularFireStorage.ref(`pictures/${currentPictureId}.jpg`).putString(this.croppedImage, 'data_url')
      pictures.then((result) => {
        this.picture = this.angularFireStorage.ref(`pictures/${currentPictureId}.jpg`).getDownloadURL()
        this.picture.subscribe((str) => {
          this.userService.setAvatar(str, this.user.uid).then(() => {

          }).catch((err) => {
            console.log(err)
          })
        })
      }).catch((err) => {
        console.log(err)
      })
    }
    this.userService.editUser(this.user).then(() => {
    }).catch((err) => {
      console.log(err)
    })
    this.router.navigate(['home'])
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
}
