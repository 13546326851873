import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../services/user.service';
import { User } from '../interfaces/user';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.css']
})
export class PersonComponent implements OnInit {
  @Input() uid: string
  person: User

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.userService.getUserById(this.uid).valueChanges().subscribe((user: User)=>{
      this.person = user
    })
  }

}
