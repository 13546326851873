import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { User } from '../interfaces/user';
import { UserService } from '../services/user.service';
import { RequestService } from '../services/request.service';
import { ConversationService } from '../services/conversation.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  user: User
  fRequests: any = []
  messages: any = []

  constructor(private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private request: RequestService,
    private conversationService: ConversationService) { }

  ngOnInit() {
    this.authService.getStatus().subscribe((status) => {
      if (status) {
        this.userService.getUserById(status.uid).valueChanges().subscribe((data: User) => {
          this.user = data
          this.request.getRequestsForEmail(this.user.email).valueChanges().subscribe((data: any) => {
            this.fRequests = data.filter(element => element.status == 'pending')
          })
          this.conversationService.getConversationsByUser(this.user.uid).valueChanges().subscribe((message: any) => {
            this.messages = message.map((val) => val.split('|').map((val2) => { if (val2 === this.user.uid) { return '' } return val2 }).join().replace(',', ''))
          })
        }, (err) => {
          console.log(err)
        })
      }
    })
  }

  logout() {
    this.authService.logOut().then((data) => {
      this.router.navigate(['login'])
    }).catch((err) => {
      console.log(err)
    })
  }

  acceptRequest(friendId) {
    this.request.addFriend(this.user.uid, friendId, this.user.email.replace('.', ','))
  }
}
